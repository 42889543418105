.input {
  width: 100%;
  height: 22px;
  padding: 0 10px;

  border-width: 0;
  background-color: hsl(0deg 0% 100% / 0%);

  font: inherit;
}

.input:focus {
  outline: none;
  outline-offset: -2px;
  background-color: #FFF4C8;
}
