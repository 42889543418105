/* CONTEXT MENU START */
.contextTrigger {
  min-height: 30px;
}

.menu {
  z-index: 5;

  overflow-y: auto;

  max-height: 200px;
  padding: 5px 0;

  border: 1px solid #999;
  background-color: #FFF;

  font-family: "Open Sans", Arial, sans-serif;
  font-size: 12px;
}

.menuItem {
  padding: 5px 12px;

  color: #000;
}

.menuItem:hover {
  background-color: #EDEDED;
}

.menuLabel {
  display: flex;
  align-items: center;

  cursor: pointer;
  text-transform: lowercase;

  line-height: 20px;
}

.menuLabel input {
  margin-right: 8px;
}

/* CONTEXT MENU END */
