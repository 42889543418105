.events {
  margin: 0;
  padding: 0;

  list-style: none;
}

.badge {
  overflow: hidden;

  width: 100%;

  white-space: nowrap;
  text-overflow: ellipsis;

  font-size: 12px;
}
