.wrap {
  flex-grow: 1;

  min-height: 32px;
  padding: 4px 8px;

  cursor: pointer;

  border: 1px solid transparent;
  border-radius: 4px;
}

.wrap:hover {
  border-color: #D9D9D9;
}

.completed {
  text-decoration: line-through;
}

.date {
  text-align: right;

  color: rgb(0 0 0 / 45%);
}
