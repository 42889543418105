.container {
  display: block;

  max-width: 100%;
}

.wrapper {
  position: relative;

  display: inline-block;

  max-width: 100%;
}

.table {
  position: relative;

  display: inline-block;
  overflow-x: auto;
  overflow-y: hidden;

  max-width: 100%;

  border-spacing: 0;

  /* border: 7px solid #F3F4F3; */
  background-color: #FFF;

  font-family: "PT Sans", sans-serif;
}

/* TOOLBAR START */
.toolbar {
  display: flex;
  flex-direction: row;

  height: 37px;
  padding-bottom: 7px;

  background-color: #F3F3F3;
}

.toolbarLeft {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 4px;
}

.toolbarRight {
  display: flex;
  flex-direction: row;
}

.toolbarLeftBtn {
  margin-right: 4px;
}

.toolbarRightBtn {
  margin-right: 8px;
}

/* TOOLBAR END */

.button {
  position: relative;

  display: inline-block;

  height: 30px;
  max-height: 30px;
  padding: 8px 10px 10px;

  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  color: #5F5F5F;
  border: 1px solid #DDD;
  border-radius: 0;
  outline: 2px solid transparent;
  outline-offset: -2px;
  background-color: #FFF;

  font-family: "PT Sans", sans-serif;
  font-size: 12px;
  line-height: 1;
}

.button:focus {
  outline-color: #FFDB4D;
}

.footer {
  display: flex;
  justify-content: flex-end;

  margin-top: 15px;
  margin-bottom: 15px;
}
