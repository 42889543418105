.button {
  display: block;

  width: 100%;
  height: 100%;
  padding: 0 12px;

  border-width: 0;
  background: rgb(255 255 255 / 0%);
}

.icon {
  position: relative;

  display: inline-flex;
  float: left;
  flex-shrink: 0;

  box-sizing: border-box;
  width: 17px;
  height: 17px;
  padding: 0;

  cursor: pointer;
  user-select: none;
  transition: color 0.3s;
  transition: all 0.3s;
  text-decoration: none;

  color: #1890FF;
  color: inherit;
  border: 1px solid #F0F0F0;
  border-radius: 2px;
  outline: none;
  background: #FFF;

  line-height: 17px;
}

.icon::before,
.icon::after {
  position: absolute;

  content: "";
  transition: transform 0.3s ease-out;

  background: currentcolor;
}

.icon::before {
  top: 7px;
  right: 3px;
  left: 3px;

  height: 1px;

  transform: rotate(-180deg);
}

.icon::after {
  top: 3px;
  bottom: 3px;
  left: 7px;

  width: 1px;

  transform: rotate(0);
}

.active::before {
  transform: rotate(0);
}

.active::after {
  transform: rotate(90deg);
}
