.container {
  overflow: hidden;

  margin: 0 -8px;
  padding-top: 12px;
}

.wrapper {
  /* box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 12px; */
  padding: 0 8px;

  background: rgb(255 255 255);
}
