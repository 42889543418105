/* TH START */
.th {
  z-index: 1;

  display: block;

  color: #555;
  background: #FFF;

  font-family: "PT Sans", sans-serif;
  font-size: 12px;
  line-height: normal;
}

.thTitle {
  position: relative;

  display: block;
  overflow: hidden;

  height: 30px;

  text-align: center;
  white-space: nowrap;

  border-right: 1px solid #F1F1F1;
  box-shadow: 0 2px 2px rgb(0 0 0 / 10%);

  line-height: 30px;
}

.thTitle::after {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;

  width: 10px;
  height: 95%;

  content: "";
  pointer-events: none;

  background-image: linear-gradient(270deg, #FFF 55%, hsl(0deg 0% 100% / 50%) 95%, rgb(255 255 255 / 0%));
}

.lastCol {
  border-right: none;
}

/* TH END */

/* SORTING START */
.arrow {
  position: absolute;
  top: 8px;
  left: 5px;

  display: block;

  width: 14px;
  height: 14px;

  background-color: #FFF;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAOCAMAAAAolt3jAAAACVBMVEUAAABNTU3////5Bsp4AAAAAXRSTlMAQObYZgAAABZJREFUeAFjIAYw0ofLCAKofAzFhAEABmsAEVQ68/YAAAAASUVORK5CYII=");
  background-repeat: no-repeat;
  background-position: 50%;
}

.arrowAsc {
  transform: rotate(180deg);
}

.sorter {
  cursor: pointer;
}

/* STORTING END */

/* RESIZER START */
.resizer {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;

  display: inline-block;

  width: 10px;
  height: 100%;

  transform: translateX(50%);

  background: rgb(255 222 255 / 0%);
  touch-action: none;
}

.resizerActive {
  background: grey;
}

/* RESIZER END */

/* FILTER START */
.thFilterBlock {
  position: relative;

  display: block;

  width: 100%;
  height: 30px;
  padding: 3px 2px;

  border: 1px solid #EAEBEA;
  border-left-width: 0;
  background-color: #FFFAF2;
  box-shadow: 0 2px 2px rgb(0 0 0 / 3%);
}

.thFilterEmptyBlock {
  position: relative;

  display: block;

  width: 100%;
  height: 30px;
  padding: 3px 2px;

  border: 1px solid #EAEBEA;
  border-left-width: 0;
  background-color: #FFF;
  box-shadow: 0 2px 2px rgb(0 0 0 / 3%);
}

/* FILTER END */
