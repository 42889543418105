.container {
  overflow: auto;

  padding: 8px 24px;

  border-radius: 4px;

  /* height: 300px; */
}

.item {
  padding: 10px;
}

.item:hover {
  cursor: pointer;

  background: rgb(0 0 0 / 2.5%);
}

.loader {
  display: flex;
  justify-content: center;

  padding: 24px;
}
