.cell {
  position: relative;

  overflow: hidden;

  height: 32px;

  white-space: nowrap;
  text-overflow: ellipsis;

  border-right: 1px solid #F1F1F1;

  font-family: "Open Sans", Arial, sans-serif;
  font-size: 12px;
  line-height: 32px;
}

.cell > span {
  display: block;
  overflow: hidden;

  height: 100%;
  padding: 0 12px;

  cursor: default;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.last {
  border-right-width: 0;
}

.hover,
.active {
  outline: 1px solid #999;
  outline-offset: -1px;
}

.active {
  background-color: #FEFEFE;
}
