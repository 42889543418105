.logo {
  position: relative;

  overflow: hidden;

  height: 48px;

  transition: all 0.3s;

  background: #001529;
  background: #FFF;

  font-size: 0;
  line-height: 48px;
}

.link {
  display: inline-block;
  display: flex;
  align-items: center;

  height: 100%;
}
