.button {
  display: block;

  width: 100%;
  height: 100%;
  padding: 0 12px;

  cursor: pointer;

  border-width: 0;
  background: rgb(255 255 255 / 0%);
}
