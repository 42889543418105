.back {
  width: auto;
  height: auto;
}

.title {
  overflow: hidden;

  margin-right: 12px;
  margin-bottom: 0;

  text-overflow: ellipsis;

  color: rgb(0 0 0 / 85%);

  font-size: 20px;
  line-height: 32px;
}

.titleWrapper {
  width: 100%;
  max-width: 25%;
}

@media (min-width: 1400px) {
  .titleWrapper {
    max-width: 30%;
  }
}

@media (min-width: 1600px) {
  .titleWrapper {
    max-width: 32%;
  }
}

.sum {
  margin-left: 34px;
}

.sumTitle {
  color: rgb(0 0 0 / 45%);

  font-size: 16px;
}

.table {
  border: 2px solid #D7D7D7;
}

.table td {
  padding: 0 10px;

  color: rgb(0 0 0 / 85%);

  font-size: 24px;
}

.table th,
.table td:first-child {
  color: rgb(0 0 0 / 45%);

  font-size: 14px;
  font-weight: 400;
}

.table td:first-child {
  padding-left: 15px;
}

.table td:last-child {
  padding-right: 15px;
}

.table th {
  padding-top: 5px;
}

.table tbody tr:last-child td {
  padding-bottom: 5px;
}
